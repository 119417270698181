import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER } from '@angular/cdk/overlay/typings/overlay-directives';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gencircular',
  templateUrl: './gencircular.component.html',
  styleUrls: ['./gencircular.component.css']
})
export class GencircularComponent implements OnInit {

  AllEvents:any = [];
  Category:string = "Academic"
  constructor(private route: ActivatedRoute, 
    private _http:HttpClient,) { }

  ngOnInit() {

    this.route.paramMap
    .subscribe(params => {
        this.Category = params.get('Category');
    })

    //this.Category = param.Category;
    
    var url="https://smc.cusmc.org/api/DMSAPI/ViewDocu?Category="+this.Category;
    this._http.get(url)
    .subscribe(data => {
      console.log(data);
      this.AllEvents = JSON.parse(data.toString());
    },
      err => {
        console.log(err);
      }
    )
  }

  download(url:any){
     var url1 ="https://smc.cusmc.org/UploadedFiles/DmsFiles/"+ url;
     window.open(url1,"_blank")
  }

}