import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookstore',
  templateUrl: './bookstore.component.html',
  styleUrls: ['./bookstore.component.css']
})
export class BookstoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
