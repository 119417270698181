import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mat-progress-bar',
  templateUrl: './mat-progress-bar.component.html',
  styleUrls: ['./mat-progress-bar.component.css']
})
export class MatProgressBarComponent implements OnInit {

  public loading:boolean;

  constructor() { }

  ngOnInit() {
    this.loading = true;
  }

}
