import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-days2019',
  templateUrl: './days2019.component.html',
  styleUrls: ['./days2019.component.css']
})
export class Days2019Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
