import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-centanary-fun2018',
  templateUrl: './centanary-fun2018.component.html',
  styleUrls: ['./centanary-fun2018.component.css']
})
export class CentanaryFun2018Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
