import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medistore',
  templateUrl: './medistore.component.html',
  styleUrls: ['./medistore.component.css']
})
export class MedistoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
