import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PhotoviewComponent } from '../photoview/photoview.component';


@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {

  AllEvents:any = [];
  AllImages:any = [];
  Site_nm: string = "";
  id:any = [];
  loading:boolean = false;
  showPopup:boolean = false;
  CurrentImg:string = "";
  CurrentIdx:number=1;
  index = 0; // Will Track Our Current Image

  constructor(private route: ActivatedRoute, 
    private _http:HttpClient,
    private elRef: ElementRef, 
    private renderer: Renderer2,
    public dialog: MatDialog) { }
    

  ngOnInit() {
    this.loading = true;
    console.log("ngOnInit- Photo")
    this.route.queryParams
    .subscribe(params => {
      console.log(params); 
      this.Site_nm = params.Site_nm;
      console.log(this.Site_nm); 
    }
  );
  // this.route.paramMap
  // .subscribe(params => {
  //     this.Site_nm = params.get('Site_nm');
  // })
     
    var url="https://smc.cusmc.org/api/GalleryAPI/GetEvents?site_nm="+ this.Site_nm ;
    this._http.get(url)
    .subscribe(data => {
      this.AllEvents = data;
      this.AllEvents.forEach(e => {
        this.getEventThumnbnail(e);
      });
      this.loading = false;
    },
      err => {
        console.log(err);
      }
    )


  }

 getEventThumnbnail(obj:any){

    var url="https://smc.cusmc.org/api/GalleryAPI/Geteventbyid?id="+obj.Event_id;
    this._http.get(url)
    .subscribe(data => {
        let data1 = data[0];
        obj.Thumbnail = data1.Thumbnail;
        this.loading = false;
    },
      err => {
        console.log(err);
      }
    )

 }

 

  openDialog(id:number,title:string): void {
    let repmodel = {
      id,
      title
    };
    const dialogRef = this.dialog.open(PhotoviewComponent, {
      width: '95vw',
      height: '95vh',
      data: repmodel
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }


  
  openDialog1(id:number,title:string): void {
    this.GetPhoto(id);
  }

  updateImage(i:number): void {

    const popupElement = this.elRef.nativeElement.querySelector('.popup');
    this.renderer.addClass(popupElement, 'active');

    //path = "https://smc.cusmc.org/gallery/"+path;

    let data = this.AllImages[i];
    console.log(data);
    this.CurrentImg =  "https://smc.cusmc.org/gallery/"+data.Name;
    this.CurrentIdx = i;
    
    //let path = `img/${i + 1}.jpg`;
    //let path = `assets/Img/test/${i+1}.jpg`;
    
   // (this.largeImage.nativeElement as HTMLImageElement).src = path;
   // (this.imageIndex.nativeElement as HTMLElement).innerHTML = `0${i + 1}`;
    this.index = i;
    
  }

  GetPhoto(id:any) {
    //this.loading = true;
    this.AllImages = [];
    var url = "https://smc.cusmc.org/api/GalleryAPI/getFiles?dir="+id;
      this._http.get(url)
      .subscribe(data1 => {
        this.AllImages = data1;
        this.updateImage(0);
      },
      err => {
        console.log(err);
      }
    )
  }

  onCloseClick(): void {
    // (this.popup.nativeElement as HTMLElement).classList.toggle('active');
    const popupElement = this.elRef.nativeElement.querySelector('.popup');
    this.renderer.removeClass(popupElement, 'active');

  }

  onLeftArrowClick(): void {
    //alert(this.index);
    if (this.index > 0) {
      this.index --;
      this.updateImage(this.index);
    }
  }

  onRightArrowClick(): void {
    //alert(this.index);
    if (this.index < this.AllImages.length - 1) {
      this.updateImage(this.index + 1);
    }
  }

}
  