import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  news:any = {};
  events:any = {};
  implink:any = {};
  marqTop:any = {};
  marqBottom:any = {};
  Photos:any = [];
  loading:number = 1;
 
  constructor(private route:ActivatedRoute, private _http:HttpClient) { }

  ngOnInit() {

    this.loading = 1;

    /* EVENTS */
    var url = "https://smc.cusmc.org/api/Website/WebpagesAPI/GetDatabyPage_nm?Page_nm=news";
    this._http.get(url)
      .subscribe(data => {
        this.news = data;
        this.loading++;
      },
        err => {
          console.log(err);
        });



    /* EVENTS */
    var url1 = "https://smc.cusmc.org/api/Website/WebpagesAPI/GetDatabyPage_nm?Page_nm=events";
    this._http.get(url1)
      .subscribe(data1 => {
        this.events = data1;
        this.loading++;
      },
        err => {
          console.log(err);
        });



    /* IMPORTANT LINK */

    var url2 = "https://smc.cusmc.org/api/Website/WebpagesAPI/GetDatabyPage_nm?Page_nm=implink";
    this._http.get(url2)
      .subscribe(data2 => {
        this.implink = data2;
        this.loading++;
      },
        err => {
          console.log(err);
        });




    /* MARQUEE TOP */

    var url3 = "https://smc.cusmc.org/api/Website/WebpagesAPI/GetDatabyPage_nm?Page_nm=marqTop";
    this._http.get(url3)
      .subscribe(data3 => {
        this.marqTop = data3;
        this.loading++;
      },
        err => {
          console.log(err);
        });


    var url4 = "https://smc.cusmc.org/api/Website/WebpagesAPI/GetDatabyPage_nm?Page_nm=marqBottom";
    this._http.get(url4)
      .subscribe(data4 => {
        console.log(data4);
        this.marqBottom = data4;
        this.loading++;
      },
        err => {
          console.log(err);
        });

    var url6 = "https://smc.cusmc.org/api/GalleryAPI/GetEvents?site_nm=PHOTO9";
    this._http.get(url6)
      .subscribe(data => {
        console.log(data);
        this.Photos = data;
        this.Photos = this.Photos.filter((photo, idx) => idx < 2);
        this.Photos.forEach(e => {
          this.getEventThumnbnail(e);
        });
      },
        err => {
          console.log(err);
        }
      )
  }
   

  getEventThumnbnail(obj:any){

    var url="https://smc.cusmc.org/api/GalleryAPI/Geteventbyid?id="+obj.Event_id;
    this._http.get(url)
      .subscribe(data => {
        console.log(data);
        let data1 = data[0];
        obj.Thumbnail = data1.Thumbnail;
    },
      err => {
        console.log(err);
      }
    )

 }
 



 closePolicy() {
      let el = document.getElementById("Announcement");
      el.classList.add("DisplayBlock");
  }
}
