import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conference2020',
  templateUrl: './conference2020.component.html',
  styleUrls: ['./conference2020.component.css']
})
export class Conference2020Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
