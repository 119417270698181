import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.css']
})

export class FacultyComponent implements OnInit {
  PageData: any= [];
  Dept_id:string = "";
  Dept_nm:string = "";
  loading:number = 1;
  constructor(private route : ActivatedRoute,
    private _http: HttpClient
    ) { }


    
  ngOnInit() {
    this.loading = 1;
    this.route.paramMap 
      .subscribe(params => {
        this.Dept_id = params.get('Dept_id');
        this.Dept_nm = params.get('Dept_nm');
      })
      var url = "https://smc.cusmc.org/api/HR/EmpmastsAPI/EmpSitebyDept_id?Dept_id="+this.Dept_id;
      this._http.get(url)
        .subscribe(data => {
          console.log(data);
          this.PageData = data;
          this.PageData.map(d => d.ImgProfile="data:image/jpeg;base64,"+d.ImgProfile);
          console.log(this.PageData);
          this.loading ++;
        },
          err => {
            console.log(err);
       });      
  }
}