import { Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PhotogalleryComponent } from './photogallery/photogallery.component';
import { LoginComponent } from './login/login.component';
import { VisionComponent } from './about/vision/vision.component';
import { YcuspComponent } from './about/ycusp/ycusp.component';
import { ManagementComponent } from './about/management/management.component';
import { HistoryComponent } from './about/history/history.component';
import { ClinicalComponent } from './services/clinical/clinical.component';
import { InfraComponent } from './infra/infra.component';
import { LibraryComponent } from './infra/library/library.component';
import { HostelComponent } from './infra/hostel/hostel.component';
import { HealthComponent } from './infra/health/health.component';
import { QuarterComponent } from './infra/quarter/quarter.component';
import { ChildrenComponent } from './infra/children/children.component';
import { CanteenComponent } from './infra/canteen/canteen.component';
import { BookstoreComponent } from './infra/bookstore/bookstore.component';
import { Days2019Component } from './photogallery/days2019/days2019.component';
import { Farewell2019Component } from './photogallery/farewell2019/farewell2019.component';
import { WorldDeafDay2019Component } from './photogallery/world-deaf-day2019/world-deaf-day2019.component';
import { WorldAudioDay2019Component } from './photogallery/world-audio-day2019/world-audio-day2019.component';
import { Freshers2019Component } from './photogallery/freshers2019/freshers2019.component';
import { CentanaryFun2018Component } from './photogallery/centanary-fun2018/centanary-fun2018.component';
import { Conference2020Component } from './photogallery/conference2020/conference2020.component';


 export const UserRoutes: Routes = [
      {
        path: 'about',
        component: AboutComponent,
        data: { title: 'About us' }
      },
      {
        path: 'about/history',
        component: HistoryComponent,
        data: { title: 'History' }
      },
      {
        path: 'about/vision',
        component: VisionComponent,
        data: { title: 'Vision & Mission' }
      },
      {
        path: 'about/ycusp',
        component: YcuspComponent,
        data: { title: 'Why C U Shah?' }
      },
      {
        path: 'about/management',
        component: ManagementComponent,
        data: { title: 'Management & Infrastructure' }
      },
      {
        path: 'contact',
        component: ContactComponent,
        data: { title: 'Contact us' }
      },
      {
        path: 'photogallery',
        component:  PhotogalleryComponent,
        data: { title: 'Photogallery' }
      },
      {
        path: 'photogallery/days2019',
        component:  Days2019Component,
        data: { title: 'March Days Celebration' }
      },
      {
        path: 'photogallery/farewell2019',
        component:  Farewell2019Component,
        data: { title: 'Farewell Celebration' }
      },
      {
        path: 'photogallery/world_deaf_day2019',
        component:  WorldDeafDay2019Component,
        data: { title: 'World Deaf Day' }
      },
      {
        path: 'photogallery/world_audio_day2019',
        component:  WorldAudioDay2019Component,
        data: { title: 'World Audiologist Day' }
      },
      {
        path: 'photogallery/freshers2019',
        component:  Freshers2019Component,
        data: { title: 'Freshers Party' }
      },
      {
        path: 'photogallery/centanary_fun2019',
        component:  CentanaryFun2018Component,
        data: { title: 'Centanary Function' }
      },
      {
        path: 'photogallery/conference2020',
        component:  Conference2020Component,
        data: { title: 'Annual Conference' }
      },
      {
        path: 'login',
        component:  LoginComponent,
        data: { title: 'Login' }
      },
      {
        path: 'services/clinical',
        component:  ClinicalComponent,
        data: { title: 'Clinical Services' }
      },
      {
        path: 'infra/academic',
        component:  InfraComponent,
        data: { title: 'Academic Facility' }
      },
      {
        path: 'infra/library',
        component:  LibraryComponent,
        data: { title: 'Library' }
      },
      {
        path: 'infra/hostel',
        component:  HostelComponent,
        data: { title: 'Hostel' }
      },
      {
        path: 'infra/health',
        component:  HealthComponent,
        data: { title: 'Health Care Service' }
      },
      {
        path: 'infra/quarter',
        component:  QuarterComponent,
        data: { title: 'Staff Quarters' }
      },
      {
        path: 'infra/children',
        component:  ChildrenComponent,
        data: { title: 'Children Park' }
      },
      {
        path: 'infra/canteen',
        component:  CanteenComponent,
        data: { title: 'Canteen' }
      },
      {
        path: 'infra/bookstore',
        component:  BookstoreComponent,
        data: { title: 'Book Store & Xerox Point' }
      },
 ];