import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-world-deaf-day2019',
  templateUrl: './world-deaf-day2019.component.html',
  styleUrls: ['./world-deaf-day2019.component.css']
})
export class WorldDeafDay2019Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
