import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { RouterModule } from '@angular/router';
import { UserRoutes } from './user.routing';
import { ContactComponent } from './contact/contact.component';
import { PhotogalleryComponent } from './photogallery/photogallery.component';
import { LoginComponent } from './login/login.component';
import { VisionComponent } from './about/vision/vision.component';
import { YcuspComponent } from './about/ycusp/ycusp.component';
import { ManagementComponent } from './about/management/management.component';
import { HistoryComponent } from './about/history/history.component';
import { ServicesComponent } from './services/services.component';
import { ClinicalComponent } from './services/clinical/clinical.component';
import { InfraComponent } from './infra/infra.component';
import { AcademicComponent } from './infra/academic/academic.component';
import { LibraryComponent } from './infra/library/library.component';
import { HostelComponent } from './infra/hostel/hostel.component';
import { QuarterComponent } from './infra/quarter/quarter.component';
import { ChildrenComponent } from './infra/children/children.component';
import { CanteenComponent } from './infra/canteen/canteen.component';
import { HealthComponent } from './infra/health/health.component';
import { BookstoreComponent } from './infra/bookstore/bookstore.component';
import { MedistoreComponent } from './infra/medistore/medistore.component';
import { Days2019Component } from './photogallery/days2019/days2019.component';
import { Farewell2019Component } from './photogallery/farewell2019/farewell2019.component';
import { WorldDeafDay2019Component } from './photogallery/world-deaf-day2019/world-deaf-day2019.component';
import { WorldAudioDay2019Component } from './photogallery/world-audio-day2019/world-audio-day2019.component';
import { Freshers2019Component } from './photogallery/freshers2019/freshers2019.component';
import { CentanaryFun2018Component } from './photogallery/centanary-fun2018/centanary-fun2018.component';
import { Conference2020Component } from './photogallery/conference2020/conference2020.component';


@NgModule({
  declarations: [AboutComponent, ContactComponent, PhotogalleryComponent, LoginComponent, VisionComponent, YcuspComponent, ManagementComponent, HistoryComponent, ServicesComponent, ClinicalComponent, InfraComponent, AcademicComponent, LibraryComponent, HostelComponent, QuarterComponent, ChildrenComponent, CanteenComponent, HealthComponent, BookstoreComponent, MedistoreComponent, Days2019Component, Farewell2019Component, WorldDeafDay2019Component, WorldAudioDay2019Component, Freshers2019Component, CentanaryFun2018Component, Conference2020Component],
  imports: [
    CommonModule,
    RouterModule.forChild(UserRoutes)
  ]
})
export class UserModule { }
