import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-freshers2019',
  templateUrl: './freshers2019.component.html',
  styleUrls: ['./freshers2019.component.css']
})
export class Freshers2019Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
