import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserModule } from './user/user.module';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SubpageComponent } from './subpage/subpage.component';
import { HttpClientModule } from '@angular/common/http';
import { FacultyComponent } from './faculty/faculty.component';
import { PhotoComponent } from './photo/photo.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PhotoviewComponent } from './photoview/photoview.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepicker, MatDatepickerModule, MatProgressBar, MatProgressBarModule, MatProgressSpinnerModule,MatIconModule } from '@angular/material';
import { MatProgressBarComponent } from './mat-progress-bar/mat-progress-bar.component';
import { GencircularComponent } from './gencircular/gencircular.component';
import { VideoComponent } from './video/video.component';
import { MainmenuComponent } from './mainmenu/mainmenu.component';
import { TestComponent } from './test/test.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SubpageComponent,
    FacultyComponent,
    PhotoComponent,
    PhotoviewComponent,
    ContactUsComponent,
    MatProgressBarComponent,
    GencircularComponent,
    VideoComponent,
    MainmenuComponent,
    TestComponent
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    UserModule,
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  entryComponents :[
    PhotoviewComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
